import React, { useState } from 'react';
import '../styles/modules/experience.less';

const Experience = () => {
    const [showDescription, setShowDescription] = useState(false);

    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };

    return (
        <div className="experience-container">
            <h1 id="experience-title">Experience</h1>

            <ul id="timeline-list">

            <li className="timeline-item" style={{ '--accent-color': '#D70015' }}>
                    <div className="headline">Newsroom Developer</div>
                    <div className="subhead">Spotlight PA</div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>November 2024 to Present  <span style={{fontWeight: 300}}> — Remote</span></div>
                    <div className="circle" onClick={toggleDescription}>
                        <span className="circle-icon"></span>
                    </div>
                </li>

            <li className="timeline-item" style={{ '--accent-color': '#0A84FF' }}>
                    <div className="headline">Newsroom Engineer (Promotion)</div>
                    <div className="subhead">Hearst Newspapers</div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>July 2024 to November 2024  <span style={{fontWeight: 300}}> — New York</span></div>
                    <div className="circle" onClick={toggleDescription}>
                        <span className="circle-icon"></span>
                    </div>
                </li>

                <li className="timeline-item" style={{ '--accent-color': '#FFCC01' }}>
                    <div className="headline">Graphics Reporter</div>
                    <div className="subhead">Times Union/Hearst</div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>July 2022 to July 2024<span style={{fontWeight: 300}}> — New York</span></div>
                    <div className="circle" onClick={toggleDescription}>
                        <span className="circle-icon"></span>
                    </div>
                </li>

                <li className="timeline-item" style={{ '--accent-color': '#34C759' }}>
                    <div className="headline">Data Analyst</div>
                    <div className="subhead">Pulitzer Center </div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>Summer 2022 <span style={{fontWeight: 300}}> — Florida</span></div>
                    <div className="circle" onClick={toggleDescription}></div>
                </li>

                <li className="timeline-item" style={{ '--accent-color': '#D70015' }}>
                    <div className="headline">Data Editor and Fellow</div>
                    <div className="subhead">Pulitzer Center</div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>Spring 2022 <span style={{fontWeight: 300}}> — Florida</span></div>
                    <div className="circle" onClick={toggleDescription}></div>
                </li>

                <li className="timeline-item" style={{ '--accent-color': '#0A84FF' }}>
                    <div className="headline">Multimedia Reporter</div>
                    <div className="subhead">WUFT News</div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>Spring 2022 <span style={{fontWeight: 300}}> — Florida</span></div>
                    <div className="circle" onClick={toggleDescription}></div>
                </li>

                <li className="timeline-item" style={{ '--accent-color': '#FFCC01' }}>
                    <div className="headline">Science/Environment Reporter</div>
                    <div className="subhead">Independent Florida Alligator</div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>Fall 2021 <span style={{fontWeight: 300}}> — Florida</span></div>
                    <div className="circle" onClick={toggleDescription}></div>
                </li>

                <li className="timeline-item" style={{ '--accent-color': '#34C759' }}>
                    <div className="headline">Health Reporter</div>
                    <div className="subhead">Independent Florida Alligator</div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>Summer 2021 <span style={{fontWeight: 300}}> — Florida</span></div>
                    <div className="circle" onClick={toggleDescription}></div>
                </li>

                <li className="timeline-item" style={{ '--accent-color': '#D70015' }}>
                    <div className="headline">Reporter</div>
                    <div className="subhead">Why Don't We Know</div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>Spring 2021 <span style={{fontWeight: 300}}> — Florida</span></div>
                    <div className="circle" onClick={toggleDescription}></div>
                </li>

                <li className="timeline-item" style={{ '--accent-color': '#0A84FF' }}>
                    <div className="headline">Contributing Writer </div>
                    <div className="subhead">Gainesville Sun</div>
                    <div className={`exp-desc ${showDescription ? 'visible' : ''}`}>Fall 2020 <span style={{fontWeight: 300}}> — Florida</span></div>
                    <div className="circle" onClick={toggleDescription}></div>
                </li>

            </ul>

        </div>
    );

}

export default Experience;
