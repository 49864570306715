import React, { useState } from 'react';
import '../styles/modules/tips.less';

const Tips = () => {
    const [tip, setTip] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [phonePlaceholder, setPhonePlaceholder] = useState("Phone number (Optional)");
    const [shakePhoneError, setShakePhoneError] = useState(false);
    const [isTipValid, setIsTipValid] = useState(true);
    const [shakeError, setShakeError] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [shakeEmailError, setShakeEmailError] = useState(false);
    const [emailPlaceholder, setEmailPlaceholder] = useState("Email (Optional)");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleTipChange = (event) => {
        setTip(event.target.value);
        setIsTipValid(event.target.value.trim() !== '');
    };

    const updateUserInfo = (newFirstName, newLastName, newEmail, newPhone) => {
        setFirstName(newFirstName);
        setLastName(newLastName);
        setEmail(newEmail);
        setPhone(newPhone);
        if (newEmail) setIsEmailValid(newEmail.includes('@'));
    };


    const handleUserInfoChange = (type, value) => {
        if (type === 'firstName') updateUserInfo(value, lastName, email, phone);
        else if (type === 'lastName') updateUserInfo(firstName, value, email, phone);
        else if (type === 'email') updateUserInfo(firstName, lastName, value, phone);
        else if (type === 'phone') {
            let formattedPhone = value.replace(/[^\d+x]/g, ''); // Remove all non-digit and non-x characters, except +
            if (formattedPhone.startsWith('+44(0)')) {
                formattedPhone = formattedPhone.replace('(0)', '');
            }
            setPhone(formattedPhone);

            // Reset validation states when user starts editing
            setIsPhoneValid(true);
            setShakePhoneError(false);
            setPhonePlaceholder("Phone number (Optional)");
        }


        if (type === 'email') {
            setEmail(value);
            setIsEmailValid(true); // Assume valid until proven otherwise
            setShakeEmailError(false); // Reset shake animation state for email
            setEmailPlaceholder("Email (Optional)"); // Reset placeholder text
        }

    };


    const handleAnimationEnd = () => {
        setShakeError(false);
        setShakeEmailError(false);
    };


    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submit action

        let formIsValid = true;

        // Check if the tip is empty
        if (tip.trim() === '') {
            setIsTipValid(false);
            setShakeError(true);
            formIsValid = false;
        }

        // Check if the email is valid
        if (email && !email.includes('@')) {
            setIsEmailValid(false);
            setEmail('');
            setShakeEmailError(true);
            setEmailPlaceholder("Invalid email");
            formIsValid = false;
        }

        // Check the phone number validity
        if (phone) {
            const digitCount = phone.replace(/[^0-9]/g, '').length; // Count only the digits
            if (digitCount < 7) {
                setIsPhoneValid(false);
                setPhone(''); // Clear the phone input
                setShakePhoneError(true);
                setPhonePlaceholder("Invalid phone number");
                formIsValid = false;
            }
        }

        if (!formIsValid) {
            setIsSubmitting(false); // Reset isSubmitting in case of validation failure
            return; // Stop form submission if validation fails
        }

        setIsSubmitting(true); // Indicate submission process is starting

        setTimeout(async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tips`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        firstName,
                        lastName,
                        email,
                        phone,
                        tip
                    })
                });


                if (response.ok) {
                    // Handle success
                    console.log("Tip submitted successfully");
                } else {
                    // Handle server errors
                    console.error("Failed to submit tip");
                }
            } catch (error) {
                // Handle network errors
                console.error("Error submitting tip:", error);
            } finally {
                setIsSubmitting(false); // Reset submitting state after form is processed
                setFormSubmitted(true); // Indicate that the form has been successfully submitted
            }
        }, 1000);
    };

    const userInfoEntered = firstName || lastName || email || phone;

    return (
        <div className="tips-container">
            <h1 id="tips-title">Contact</h1>
            <p className="tips-description">
                Looking to get in touch?
                Use the form below to submit the message directly to me.
                You may choose to remain anonymous.
            </p>

            {formSubmitted ? (
                <div className="success-container fade-in">
                    <div className="success-top">
                        <div className="submission-success-indicator">
                            <i className="fas fa-check form-icon"></i>
                        </div>
                    </div>
                    <div className="success-bottom">
                        <p id="success-message">Success!</p>
                        <p className="submission-thank-you">You have submitted a message. Thank you.</p>
                    </div>
                </div>



            ) : (
                // Display the form if it has not been submitted yet
                <>
                    <div className={`form-content ${formSubmitted ? 'fade-out' : ''}`}>

                        <div className="icon-container">
                            <i className={`fas ${userInfoEntered ? 'fa-user' : 'fa-user-secret'} tips-icon`}></i>
                        </div>

                        <div className="name-container">
                            <textarea id="firstName" name="firstName" autoComplete="given-name" className="tips-first" placeholder="First name (Optional)" onChange={(e) => handleUserInfoChange('firstName', e.target.value)}></textarea>
                            <textarea id="lastName" name="lastName" autoComplete="family-name" className="tips-last" placeholder="Last name (Optional)" onChange={(e) => handleUserInfoChange('lastName', e.target.value)}></textarea>
                        </div>

                        <div className="contact-container">
                            <textarea
                                id="email"
                                name="email"
                                autoComplete="email"
                                className={`tips-email ${!isEmailValid ? 'invalid-input shake-animation' : ''}`}
                                placeholder={emailPlaceholder}
                                onChange={(e) => handleUserInfoChange('email', e.target.value)}
                                onAnimationEnd={handleAnimationEnd}
                                value={email}
                            ></textarea>
                            <textarea
                                id="phone"
                                name="phone"
                                autoComplete="tel"
                                className={`tips-phone ${!isPhoneValid ? 'invalid-input shake-animation' : ''}`}
                                placeholder={phonePlaceholder}
                                onChange={(e) => handleUserInfoChange('phone', e.target.value)}
                                onAnimationEnd={handleAnimationEnd}
                                value={phone}
                            ></textarea>
                        </div>

                        <textarea
                            id="tip"
                            name="tip"
                            className={`tips-input ${!isTipValid ? 'invalid-input' : ''} ${shakeError ? 'shake-animation' : ''}`}
                            placeholder="Enter your message here (Required)"
                            onChange={handleTipChange}
                            value={tip}
                            onAnimationEnd={handleAnimationEnd}
                        ></textarea>
                        {
                            isSubmitting ? (
                                <div className="tips-submit">
                                    <div className="loader"></div>
                                </div>
                            ) : (
                                <button
                                    className="tips-submit"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            )
                        }


                    </div>
                </>
            )}
        </div>
    );

}

export default Tips;